/* You can add global styles to this file, and also import other style files */

/*テーマ設定*/
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
  height: 100%;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial,
    helvetica, sans-serif;
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}
body.dialog {
  overscroll-behavior-x: none !important;
}

app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.main {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}

.mat-mdc-subheader {
  display: flex;
  box-sizing: border-box;
  padding: 28px 16px;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin-left: 0 !important;
  letter-spacing: 0;
}
.mdc-list-group__subheader {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial,
  helvetica, sans-serif;
}

/* 入力系 */
.mat-mdc-form-field .mdc-text-field {
  padding: 0 5px;
}
.mat-mdc-form-field .mat-mdc-form-field-infix {
  min-height: auto;
}
.mdc-text-field--filled {
  background-color: #fff !important;
}
.mat-mdc-form-field.no-line .mdc-text-field--filled .mdc-line-ripple::before,
.mat-mdc-form-field.no-line .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-width: 0 !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: #fff !important;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 4px;
  padding-bottom: 4px;
}
.order_no .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 1;
}
.order_no .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) {
  margin-top: 1px;
}
.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  display: none;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 26px;
}
.mat-mdc-nav-list .mat-mdc-list-item {
  height: 40px !important;
}
textarea {
  white-space: break-spaces;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial,
    helvetica, sans-serif !important;
  resize: vertical;
}
/* ボタン */
.mdc-button--raised {
  padding: 0 10px !important;
}
.title-area .mdc-button--raised {
  padding: 0 16px !important;
}
.mat-mdc-raised-button.yes-btn:not(:disabled) {
  background: #ff5722;
  color: #ffffff;
}

/* チェックボックス */
.mdc-checkbox__background {
  width: 16px !important;
  height: 16px !important;
}
.mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
  left: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
}
.mat-mdc-checkbox-touch-target {
  height: 36px !important;
  width: 36px !important;
}

/* ラジオボタン */


/* セレクトボックス */
.mat-mdc-option {
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-height: 40px !important;
}
.spinner {
  margin-top: 20px;
}
.spinner .mat-mdc-progress-spinner {
  margin: 0 auto;
}
.spinner mat-spinner circle {
  stroke: #D4D4D4;
}
a,
button {
  cursor: pointer;
}
.mdc-button,
.mat-mdc-checkbox .mdc-form-field,
.mat-mdc-select,
.mat-mdc-radio-button .mdc-form-field,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  -moz-osx-font-smoothing: auto !important;
  -webkit-font-smoothing: auto !important;
}
.mdc-form-field>label {
  padding-left: 0 !important;
}

/* タレントリスト-検索タブ-ラベル文字数が多い場合に折り返す */
.contents-title-checkbox label.mat-checkbox-layout,
.question-checkbox label.mat-checkbox-layout {
  white-space: normal !important;
}
.contents-title-checkbox .mat-checkbox-inner-container,
.question-checkbox .mat-checkbox-inner-container {
  margin-top: 3px !important;
}

/* タレントリストテーブルの一括チェックボックス */
.talent-table .check-cell.all .mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mdc-checkbox__background{
  border-color: #ffffff !important;
}

/* テキストエリアの最大文字数オーバー時の色 */
.length-red {
  color: #ff5722 !important;
}
.red-text {
  color: #ff5722;
}

/* ダイアログ */
.mat-mdc-dialog-actions.mdc-dialog__actions {
  padding: 0 0 20px;
}
mat-dialog-content .label .normal {
  color: #999999;
  font-weight: normal;
}

/* PDF */
.ng2-pdf-viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdfViewer.removePageBorders .page {
  margin-bottom: 0 !important;
}

/* LPモバイルメニュー背景 */
.menu-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

/* chromeで画像がぼやける対応 */
_:lang(x)::-internal-media-controls-overlay-cast-button, img {
  image-rendering: -webkit-optimize-contrast;
}

/* エラートーストの横幅設定 */
.ngx-toastr.toast-error {
  width: 435px !important;
}

/* メニュー */
.mat-mdc-menu-item {
  min-height: 40px !important;
}

html {
  --mdc-list-list-item-label-text-font: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial,
  helvetica, sans-serif;
  --mdc-list-list-item-label-text-size: 13px;
  --mdc-typography-font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial,
  helvetica, sans-serif;
  --mdc-typography-button-font-family: Roboto, "Helvetica Neue", sans-serif;
  --mdc-typography-button-letter-spacing: normal;
  --mdc-typography-button-font-weight: 500;
  --mdc-typography-body2-font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial,
  helvetica, sans-serif;
  --mdc-typography-body2-letter-spacing: 0;
  --mdc-radio-state-layer-size: 36px;
  --mdc-checkbox-state-layer-size: 24px;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-tracking: 0;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mat-form-field-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-subscript-text-tracking: 0;
  --mdc-filled-text-field-label-text-size: 13px;
  --mat-option-label-text-size: 13px;
  --mat-option-label-text-tracking: 0;
  --mat-option-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-select-trigger-text-size: 13px;
  --mat-select-trigger-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-menu-item-label-text-size: 13px;
  --mat-expansion-header-text-tracking: 0;
}
.mat-mdc-radio-button.mat-primary {
  --mat-radio-checked-ripple-color: transparent;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-checked-ripple-color: rgba(63, 81, 181, .5);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-focus-state-layer-color: transparent;
  --mdc-checkbox-unselected-focus-state-layer-color: transparent;
  --mdc-checkbox-unselected-focus-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field .mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 20px;
  padding: 14px;
}
.mat-mdc-unelevated-button {
  --mdc-filled-button-container-height: 30px;
}

::placeholder {
  color: #cccccc;
}

.fa-brands {
  font-size: 18px;
}

/* quillエディタ共通 */
.ql-container.ql-snow {
  font-size: 16px;
}
.ql-snow.ql-toolbar button.ql-header,
.ql-snow.ql-toolbar button.ql-toc  {
  width: auto !important;
}
.ql-snow.ql-toolbar button.ql-video img {
  width: 18px;
}
.ql-snow.ql-toolbar button.ql-active.left .ql-stroke{
  stroke: #444 !important;
}
.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #3f51b5 !important;
}
.ql-snow .ql-tooltip {
  font-size: 13px;
}
.ql-snow .ql-tooltip a {
  color: #3f51b5 !important;
}
.ql-snow .ql-editor {
  line-height: 2.25em;
}
.ql-snow .ql-editor h3 {
  margin: 32px 0 16px 0;
  font-size: 28px !important;
  line-height: 1.42;
}
.ql-snow .ql-editor h3:has(+.ogp-div),
.ql-snow .ql-editor h3:has(+.ogp-loading),
.ql-snow .ql-editor h3:has(+.toc-div) {
  margin-bottom: 0 !important;
}
.ql-snow .ql-editor h4 {
  margin: 24px 0 12px 0;
  font-size: 20px !important;
  line-height: 1.42;
}
.ql-snow .ql-editor h4:has(+.ogp-div),
.ql-snow .ql-editor h4:has(+.ogp-loading),
.ql-snow .ql-editor h4:has(+.toc-div) {
  margin-bottom: 0 !important;
}
.ql-snow .ql-editor img {
  width: 100%;
  display: block;
  margin: 16px 0;
  user-select: none;
}
.ql-editor .ql-video {
  width: 100%;
  height: 320px;
  margin: 16px 0 !important;
}
/*引用/強調*/
.ql-snow .ql-editor blockquote {
  background-color: #F8F8F8;
  border: 0 !important;
  padding: 16px 32px !important;
  margin: 16px 0 !important;
}
/*箇条書き*/
.ql-editor ul {
  padding-left: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.ql-editor ul li:not(.ql-direction-rtl):not(:last-child) {
  padding-bottom: 4px !important;
}
.ql-editor ul li:not(.ql-direction-rtl)::before {
  position: relative;
  right: 4px;
}
/*段落番号*/
.ql-editor ol {
  padding-left: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.ql-editor ol li:not(.ql-direction-rtl):not(:last-child) {
  padding-bottom: 4px !important;
}
/*中央揃え*/
.ql-editor ul .ql-align-center {
  padding-left: 8px !important;
}
.ql-editor ol .ql-align-center {
  padding-left: 8px !important;
}
/*リンク*/
.ql-snow a {
  color: #434343 !important;
}
.ql-snow .ql-editor img:has(+.ogp-div),
.ql-snow .ql-editor img:has(+.ogp-loading),
.ql-snow .ql-editor img:has(+.toc-div),
.ql-snow .ql-editor .ql-video:has(+.ogp-div),
.ql-snow .ql-editor .ql-video:has(+.ogp-loading),
.ql-snow .ql-editor .ql-video:has(+.toc-div),
.ql-snow .ql-editor blockquote:has(+.ogp-div),
.ql-snow .ql-editor blockquote:has(+.ogp-loading),
.ql-snow .ql-editor blockquote:has(+.toc-div),
.ql-snow .ql-editor ul:has(+.ogp-div),
.ql-snow .ql-editor ul:has(+.ogp-loading),
.ql-snow .ql-editor ul:has(+.toc-div),
.ql-snow .ql-editor ol:has(+.ogp-div),
.ql-snow .ql-editor ol:has(+.ogp-loading),
.ql-snow .ql-editor ol:has(+.toc-div) {
  margin-bottom: 0 !important;
}

/* 目次 */
.ql-editor .toc-div {
  display: inline-block;
  margin: 24px 0;
  padding: 16px;
  background: #F8F8F8;
  width: 100%;
  vertical-align: top;
  text-align: left !important;
  -webkit-user-drag: none;
}
.ql-editor .toc-div:has(+h3),
.ql-editor .toc-div:has(+h4),
.ql-editor .toc-div:has(+.toc-div) {
  margin-bottom: 0 !important;
}
.ql-editor .toc-div:has(+img),
.ql-editor .toc-div:has(+iframe),
.ql-editor .toc-div:has(+.ogp-div),
.ql-editor .toc-div:has(+.ogp-loading),
.ql-editor .toc-div:has(+blockquote) {
  margin-bottom: 8px;
}
.ql-editor .toc-div:has(+ul),
.ql-editor .toc-div:has(+ol) {
  margin-bottom: 16px;
}
.ql-editor .toc-title {
  font-weight: bold;
  padding-bottom: 16px;
}
.ql-editor .toc-title.bottom0 {
  padding-bottom: 0;
}
.ql-editor .toc-title.bottom0 span{
  color: #666;
  font-weight: normal;
}
.ql-editor div.header-H3 {
  color: #666;
}
.ql-editor hr.header-H3 {
  margin: 8px 0;
}
.ql-editor div.header-H4 {
  margin-left: 16px;
  color: #666;
}
.ql-editor hr.header-H4 {
  margin: 8px 0 8px 16px;
}

/* OGP */
.ql-editor .ogp-loading {
  display: inline-table;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 80px;
  margin: 16px 0;
  -webkit-user-drag: none;
}
.ql-editor .ogp-div {
  display: block;
  vertical-align: top;
  text-align: left !important;
  margin: 16px 0;
}
.ql-editor .ogp-div:has(+.toc-div),
.ql-editor .ogp-div:has(+.ogp-div),
.ql-editor .ogp-div:has(+h3),
.ql-editor .ogp-div:has(+h4),
.ql-editor .ogp-div:has(+img),
.ql-editor .ogp-div:has(+iframe),
.ql-editor .ogp-div:has(+.ogp-loading),
.ql-editor .ogp-div:has(+blockquote) {
  margin-bottom: 0 !important;
}
.ql-editor .ogp-div:has(+ul),
.ql-editor .ogp-div:has(+ol) {
  margin-bottom: 8px;
}
.ql-editor .ogp-div:has(a) {
  display: inline-table;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 80px;
}
.ql-editor .ogp-div a {
  display: table-cell;
  padding: 16px;
  vertical-align: middle;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 12px;
}
.ql-editor .ogp-div a span {
  display: block;
  overflow: hidden;
  line-height: 1.5;
  opacity: 0.5;
}
.ql-editor .ogp-div a strong {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.42;
  opacity: 1;
}
.ql-editor .ogp-div a span.desc {
  max-height: 3em;
  margin-bottom: 4px;
}
.ql-editor .ogp-div a.og-image {
  width: 220px;
  padding: 0;
  border-left: #08131A08;
}
.ql-editor .ogp-div a.og-image img{
  vertical-align: middle;
  object-fit: cover;
  height: 100%;
  max-height: 180px;
  border-radius: 0 4px 4px 0;
  margin: 0;
}
/* スピナー */
.ql-editor .loading {
  width: 30px;
	height: 30px;
	clear: both;
	margin: 24px auto;
	border: 4px #3f51b5 solid;
	border-top: 4px transparent solid;
  border-right: 4px transparent solid;
	border-radius: 50%;
	-webkit-animation: spCircRot .6s infinite linear;
	animation: spCircRot .6s infinite linear;
}
@-webkit-keyframes spCircRot {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(359deg); }
}
@keyframes spCircRot {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}
